<template>
  <div>
    <b-modal @show="show" title="Projects" id="projects_list" ref="projects_list" size="xl" centered
             style="height: 75%;">
      <b-overlay :show="showOverlay" rounded="sm">
        <div class="w-100 h-100 overflow-auto">
          <b-table striped :items="projects" :fields="fields">
            <template #cell(name)="data">
              <i class="fas fa-pen-alt edit-icon text-muted"/>
              <div contenteditable="true" @focusout="saveProjectName($event,data.item)">{{ data.item.name }}</div>
            </template>
            <template #cell(owner)="data">
              <span v-if="data.item.user.id === user.id">You</span>
              <span v-else>{{ data.item.user.first_name }} {{ data.item.user.last_name }}</span>
              <b-button variant="info" @click="showChangeOwnerWindow(data.item)"
                        :disabled="!userIsOwner(data.item)"
                        class="float-right" size="sm">Change owner
              </b-button>
            </template>
            <template #cell(shared)="data">
              <b-checkbox @change="shareProject(data.item.id,data.item.is_shared)"
                          :checked="data.item.is_shared===1" :disabled="!userIsOwner(data.item)"></b-checkbox>
            </template>
            <template #cell(actions)="data">
              <div class="float-right">
                <b-button :disabled="data.item.is_empty" variant="success" @click="emptyProject(data.item.id)"
                          size="sm">
                  Empty
                </b-button>
                <b-button class="ml-2" variant="info" @click="projectSummary(data.item.id)" size="sm">
                  Project Summary
                </b-button>
                <b-button class="ml-2"
                          :disabled="!data.item.is_empty  || data.item.id==selected_project"
                          variant="danger"
                          @click="deleteProject(data.item.id)" size="sm">
                  <b-icon-trash-fill></b-icon-trash-fill>
                </b-button>
                <b-button class="ml-2" :disabled="(data.item.id==selected_project)" variant="success"
                          @click="selectProject(data.item)" size="sm">
                  Select
                </b-button>

              </div>
            </template>
          </b-table>
        </div>
      </b-overlay>
      <template #modal-footer>
        <div class="w-100">
          <b-button variant="primary" size="sm" class="float-right" v-b-modal.new_project>
            Add New Project
          </b-button>
        </div>
      </template>
    </b-modal>
    <NewProject></NewProject>

    <b-modal id="change_owner" title="Change owner" @ok="changeOwner" ok-only ok-title="Change"
             no-close-on-backdrop>
      <b-form-select
          v-model="choosedProjecUser"
          :options="owners"
          class="mb-3"
          value-field="id"
          text-field="full_name"
      ></b-form-select>
    </b-modal>
    <b-modal id="project_summary" size="xl" centered title="Project Summary" ok-only>
      <b-overlay :show="showProjectSummaryOverlay" rounded="sm">
        <div v-if="summary.roadworks>0" class="alert alert-danger">Operator Roadworks Recently in Area</div>
        <div class="p-4">
          <div class="row">
            <strong>Properties in polygon : </strong>
            <div> {{ summary.properties_in_polygon }} ({{ summary.filtered_properties_in_polygon }} within criteria)
            </div>
          </div>
          <div v-if="summary.properties.length>0" class="row">
            <strong>Flags</strong>

            <ul class='col-12 project_summary'>
              <li v-for="(property,index) in summary.properties" :key="index"><img :src='getFlag(property.icon)'
                                                                                   class='property_flag'/>{{
                  property.total
                }}
              </li>
            </ul>
          </div>
          <div v-if="summary.surface.list.length>0" class="row">
            <strong>Ducting</strong>
            <div class="col-12" id="duct_surface_summary">
              <table class="table-bordered table">
                <thead>
                <tr>
                  <th></th>
                  <th v-for="header in summary.surface.list[0].data" :key="header.name">{{ header.name }}
                  </th>
                  <th>Subtotal</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(surface,index) in summary.surface.list" :key="index">
                  <td><strong>{{ surface.name }}</strong>
                    <br/>
                    <small>({{ summary.surface.l[surface.duct_type_id] }}m)</small>
                  </td>
                  <td v-for="s in surface.data" :key="s.name">{{ s.sum }}m

                  </td>
                  <td><strong>£{{ numberFormat(summary.surface.summary[surface.duct_type_id]) }}</strong></td>

                </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div v-if="summary.object_placement.list.length>0" class="row">
            <strong>Objects</strong>
            <div class="col-12" id="object_surface_summary">
              <table class="table-bordered table">
                <thead>
                <tr>
                  <th></th>
                  <th v-for="header in summary.object_placement.list[0].data" :key="header.name">{{ header.name }}</th>
                  <th>Subtotal</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(surface,index) in summary.object_placement.list" :key="index">
                  <td>
                    <div class="">
                      <div v-if="surface.badge !== undefined && surface.badge !== null && surface.badge.length>0"
                           class="badge badge-info">{{ surface.badge }}
                      </div>
                      <strong class="ml-3">{{ surface.name }} </strong>
                    </div>
                  </td>
                  <td v-for="s in surface.data" :key="s.name">{{ s.count }}
                  </td>
                  <td><strong>£{{ numberFormat(summary.object_placement.summary[surface.object_type_id]) }}</strong>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>

          </div>
          <p class="align-content-center text-center"
             v-if="summary.surface.list.length===0 && summary.properties.length===0 && summary.object_placement.list.length===0">
            Project is empty</p>
        </div>

      </b-overlay>
      <template #modal-footer="">
        <b class="float-left row">Project Cost : £{{ numberFormat(summary.summary) }}</b>

      </template>
    </b-modal>
  </div>
</template>

<script>
import NewProject from "@/components/modals/Project/NewProject";
import {mapGetters} from "vuex";
import DownloadPropertyInfo from "@/Mixins/DownloadPropertyInfo";
import CommonTools from "@/Helpers/CommonTools";

export default {
  name: "Projects",
  components: {NewProject},
  mixins: [DownloadPropertyInfo],
  data() {
    return {
      flags: {
        blue: 'flag_blue.png',
        green: 'flag_green.png',
        orange: 'flag_orange.png',
        pink: 'flag_pink.png',
        purple: 'flag_purple.png',
        red: 'flag_red.png',
        yellow: 'flag_yellow.png'
      },
      showOverlay: false,
      showProjectSummaryOverlay: false,
      fields: [{key: 'name', label: 'Project Name'}, 'owner', 'shared', {
        key: 'actions',
        label: 'Actions',
        class: 'text-right'
      }],
      choosedProject: {},
      choosedProjecUser: 0,
      summary: {
        ducts: [],
        objects: [],
        properties: [],
        properties_in_polygon: 0,
        filtered_properties_in_polygon: 0,
        surface: {list: [], summary: [], l: []},
        object_placement: {list: [], summary: [], c: []},
        summary: 0,
        roadworks: 0
      },
      projectId: 0,
      summaryType: 0,
    }
  },
  computed: {
    owners: {
      get() {
        return this.$store.state.Accounts.accounts;
      }
    },

    projects: {
      get() {
        return this.$store.state.Projects.projects;
      }
    },
    selected_project: {
      get() {
        return this.$store.state.Projects.selected_project.id;
      }
    },
    ...mapGetters({
      user: 'Profile/getUser'
    })
  },
  methods: {
    show() {
      this.showOverlay = true;
      this.$store.dispatch('Projects/getProjects').finally(() => {
        this.showOverlay = false;
      })
      this.downloadKML(this.$store.state.Projects.selected_project.id)
      this.downloadCsv(this.$store.state.Projects.selected_project.id)
    },
    showChangeOwnerWindow(project) {
      this.choosedProject = project;
      this.choosedProjecUser = project.user.id;
      this.$store.dispatch('Accounts/getAccounts').then(response => {
        this.$store.commit('Accounts/setAccounts', response.data.data)
        this.loading = false;
        this.$bvModal.show('change_owner')
      }).catch(() => {
        this.loading = false;
      })

    },
    changeOwner() {
      this.showOverlay = true;
      const data = {user_id: this.choosedProjecUser, projectId: this.choosedProject.id};
      this.$store.dispatch('Projects/updateProject', data).then(() => {
        this.$store.dispatch('Projects/getProjects')
      }).finally(() => {
        this.showOverlay = false;
      });
    },

    projectSummary(projectId) {
      this.projectId = projectId;
      this.showProjectSummaryOverlay = true;
      const self = this;
      this.$swal({
        width: '50rem',
        title: 'Summary Type?',
        text: 'Which summary do you want to see?',
        icon: 'warning',
        showCancelButton: true,
        showDenyButton: true,
        confirmButtonText: 'Planned Duct / Chambers',
        cancelButtonText: 'Both',
        denyButtonText: 'Existing Duct / Chambers',


      })
          .then((result) => {

            self.summaryType = -1;
            if (result.isConfirmed) {
              // Planned Duct / Chambers
              self.summaryType = 0;
            } else if (result.isDenied) {
              // Existing Duct / Chambers
              self.summaryType = 1;
            }
            this.showOverlay = true

            self.getSummary(projectId, self.summaryType).then(() => {
              self.$swal().close();
              self.$bvModal.show('project_summary');
              this.showOverlay = false;
            })
          })

    },
    getSummary(projectId, summaryType) {
      const self = this;
      return new Promise((resolve, reject) => {
        self.$store.dispatch('Projects/getProjectSummary', {projectId, summaryType}).then(resp => {
          self.summary.properties = resp.data.data.properties;
          self.summary.ducts = resp.data.data.ducts;
          self.summary.objects = resp.data.data.objects;
          self.summary.surface.list = resp.data.data.surface.list;
          self.summary.surface.summary = resp.data.data.surface.summary;
          self.summary.surface.l = resp.data.data.surface.l;
          self.summary.object_placement.list = resp.data.data.object_placement.list;
          self.summary.object_placement.summary = resp.data.data.object_placement.summary;
          self.summary.object_placement.c = resp.data.data.object_placement.c;
          self.summary.summary = resp.data.data.summary;
          self.summary.properties_in_polygon = resp.data.data.properties_in_polygon;
          self.summary.filtered_properties_in_polygon = resp.data.data.filtered_properties_in_polygon;
          self.summary.roadworks = resp.data.data.roadworks;
          self.showProjectSummaryOverlay = false;
          resolve(resp.data.data);

        });
      })
    },

    shareProject(projectId, state) {
      state = state == 1 ? 0 : 1;
      const data = {is_shared: state, projectId: projectId};
      this.$store.dispatch('Projects/updateProject', data)
    },
    emptyProject(projectId) {
      const self = this;
      CommonTools.deleteConfirmation().then(() => {
        self.$store.dispatch('Projects/emptyProject', projectId).then(() => {
          window.location.reload();
        });
      });
    },
    deleteProject(projectId) {
      const self = this;
      CommonTools.deleteConfirmation().then(() => {
        self.$store.dispatch('Projects/deleteProject', projectId).then(() => {
          self.$store.dispatch('Projects/getProjects');
        });
      });
    },
    selectProject(project) {
      this.$store.dispatch('Projects/selectProject', project.id).then(() => {
        this.$root.$emit('selected_project_changed', project.id);
        this.$bvModal.hide('projects_list');

      });
    },
    getFlag(color) {
      if (this.flags[color] === undefined) {
        return '/assets/img/icons/flags/' + this.flags.green;
      } else {
        return '/assets/img/icons/flags/' + this.flags[color];
      }
    },
    numberFormat(number) {
      let num = parseFloat(number);
      return num.toLocaleString('en-US', {maximumFractionDigits: 2}) // "1,234.57"
    },

    saveProjectName(e, project) {
      if (e.target.innerText !== project.name) {
        this.$store.dispatch('Projects/updateProject', {projectId: project.id, name: e.target.innerText});
      }
    },
    userIsOwner(project) {
      // if project user id is current user or parent_user_id is null that means current user is master
      return project.user.id === this.user.id || this.user.parent_user_id === null;
    }
  }
}
</script>

<style scoped>
.duct-color, .object-color {
  width: 32px;
  height: 32px;
  border-radius: 8px;
  border: 1px solid #cccccc;
  float: left;
}

ul.project_summary {
  list-style: none;
}

ul.project_summary li {
  width: 100%;
  float: left;
  margin-top: 5px;
}

#duct_surface_summary, #object_surface_summary {
  overflow: auto;
}

#duct_surface_summary table tbody, #object_surface_summary table tbody {
  font-size: 11px;
}

#duct_surface_summary table thead, #object_surface_summary table thead {
  font-size: 12px;
}

.edit-icon {
  float: left;
  font-size: 9px;
  padding-top: 4px;
  padding-right: 2px;
}
</style>