import axios from "axios";
import Vue from 'vue';

const state = {
    projects: [],
    selected_project: {},
    area: null,
};
const mutations = {
    setProjects(state, data) {
        Vue.set(state, 'projects', data);
    },
    setSelectedProject(state, data) {
        Vue.set(state, 'selected_project', data);
    }
};
const getters = {
    selectedProject: state => {
        return state.selected_project
    }
};
const actions = {
    getProjects({commit}) {
        return new Promise((resolve, reject) => {
            commit('setProjects', []);
            axios.get(process.env.VUE_APP_API_URL + '/api/project')
                .then(response => {
                    commit('setProjects', response.data.data);
                    resolve();
                }).catch(() =>
                reject()
            )
        })
    },
    getProject({commit}, projectId) {
        return axios.get(process.env.VUE_APP_API_URL + '/api/project/' + projectId);
    },
    getSelectedProject({commit, dispatch}) {
        return new Promise((resolve, reject) => {
            axios.get(process.env.VUE_APP_API_URL + '/api/project/selected').then(response => {
                commit('setSelectedProject', response.data.data);
                Vue.nextTick(function () {
                    dispatch('LayersList/load_location_photosActivate', {}, {root: true});
                })
                resolve(response.data.data);
            }).catch(error => {
                reject(error);
            })
        })
    },
    getFullInfo({commit}, projectId) {
        return axios.get(process.env.VUE_APP_API_URL + '/api/project/' + projectId + '/full');
    },
    getNetworkingFullInfo({commit}, projectId) {
        return axios.get(process.env.VUE_APP_NETWORKING_API_URL + '/api/project/' + projectId + '/info');
    },
    selectProject({commit}, projectId) {
        return new Promise((resolve, reject) => {
            axios.put(process.env.VUE_APP_API_URL + '/api/project/' + projectId, {selected: true})
                .then((data) => {
                    commit('setSelectedProject', data.data.data);
                    resolve(data.data.data);
                }).catch((error) => {
                reject(error);
            })
        })
    },
    deleteProject({}, projectId) {
        return axios.delete(process.env.VUE_APP_API_URL + '/api/project/' + projectId);
    },
    clearArea({}, projectId) {
        return axios.delete(process.env.VUE_APP_API_URL + '/api/project/' + projectId + '/area');
    },
    addNewProject({}, data) {
        return axios.post(process.env.VUE_APP_API_URL + '/api/project', data);
    },
    emptyProject({}, projectId) {
        return axios.get(process.env.VUE_APP_API_URL + '/api/project/' + projectId + '/clear')
    },
    updateProject({}, data) {
        const projectId = data.projectId;
        data.projectId = null;
        return axios.put(process.env.VUE_APP_API_URL + '/api/project/' + projectId, data);
    },
    getProjectSummary({}, data) {
        return axios.get(process.env.VUE_APP_NETWORKING_API_URL + '/api/project/' + data.projectId + '/summary/' + data.summaryType);
    }

};

export default {
    namespaced: true,
    state,
    mutations,
    getters,
    actions
}